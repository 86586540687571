define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/productAddon',

  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',
  'upx.modules/ProductsModule/collections/TranslatedCategory',
  'modules/common/collections/DeepModelCollection',
  'upx.modules/ShopModule/collections/Shop',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'upx.modules/ShopModule/models/ShopProductAddonGroup',
  './categoryCache',
], (
  $, _, Backbone,
  Locale, Toaster, ProductAddon,
  NaturalSearchShopFlatProductCollection, TranslatedCategoryCollection, DeepModelCollection, ShopCollection,
  DefaultShopConfiguration, ShopProductAddonGroupModel, CategoryCache,
) => ({

  product_cache: new NaturalSearchShopFlatProductCollection(),

  is_loaded: false,

  product_limit: 1000,

  last_error: false,

  load() {
    const def = new $.Deferred();

    if (this.is_loaded) {
      def.resolve(this);
    } else {
      const product_collection = new NaturalSearchShopFlatProductCollection();

      const product_parameters = this.productFetchParameters();

      $.when(
        product_collection.fetchAll({ params: product_parameters, limit: this.product_limit }),
        CategoryCache.load(),
      ).then(() => {
        const product_total = product_collection.total;

        this.product_cache = product_collection;
        this.is_loaded = true;

        const shop_products_ids = product_collection.pluck('id');
        const shop_product_addon_group_model = new ShopProductAddonGroupModel();
        const shop_product_addon_group_parameters = {
          shop_product_ids: shop_products_ids,
          lang: Locale.getLocale(),
        };

        shop_product_addon_group_model.getForShopProductIds(shop_product_addon_group_parameters)
          .then((shop_product_addon_response) => {
            ProductAddon.setMultipleShopProductAddons(
              shop_products_ids,
              shop_product_addon_response.data,
            );

            this.is_loaded = true;
            def.resolve(this);

            // Check product total
            if (product_total > this.product_limit) {
              Toaster.error(Locale.translate('there_are_more_active_products_than_then_allowed_{0}_there_are_{1}_products_active_dot', [
                this.product_limit,
                product_total,
              ]));
            }
          });
      }, def.reject);
    }

    return def;
  },

  unload() {
    this.product_cache = new NaturalSearchShopFlatProductCollection();
    this.is_loaded = false;
  },

  reload() {
    this.unload();
    return this.load();
  },

  productFetchParameters() {
    return {
      query: 0,
      lang: Locale.getLocale(),
      start: 0,
      limit: 250,
      sort: [{
        name: 'flat_product/title',
        dir: 'asc',
      }],
      filters: [{
        name: 'flat_product/product/type__in_list',
        multi_val: [
          'configurable_assign',
          'simple',
        ],
      }, {
        name: 'active__=',
        val: '1',
      }, {
        name: 'product_price/ppu_wt__notnull',
        val: true,
      }],
    };
  },

  getProductCollection() {
    return this.product_cache;
  },

  getCategoryCollection() {
    return CategoryCache.getCategoryCollection();
  },

  SEARCH_MODE_BARCODE: 'search-barcode',
  SEARCH_MODE_PRODUCT: 'search-product',

  searchMode: 'search-barcode', // On page reload it defaults to the barcode search method.
  getSearchMode() {
    return this.searchMode;
  },

  setSearchMode(searchMode) {
    this.searchMode = searchMode;
  },

}));
