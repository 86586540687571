define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/ShopModule/models/ShopProductAddonGroup',

  'modules/common/collections/DeepModelCollection',

  'modules/common/components/locale',
], (
  $, _, Backbone,
  ShopProductAddonGroupModel,
  DeepModelCollection,
  Locale,
) => ({

  cache_def: {},
  cache: {},

  setMultipleShopProductAddons(shop_product_ids, data) {
    const collection = this.prepareResponseData(data);
    _.each(shop_product_ids, (shop_product_id) => {
      this.setShopProductAddonsFromPreparedCollection(shop_product_id, collection);
    });
  },

  prepareResponseData(data) {
    data.forEach((item) => {
      item.order = item.product_addon_group.order;
      item.id = item.product_addon_group.id;
    });
    return new DeepModelCollection(data);
  },

  resolveWithShopProduct(def, shop_product_id) {
    def.resolve(new DeepModelCollection(this.cache[shop_product_id]));
    return def.promise();
  },

  setShopProductAddonsFromPreparedCollection(shop_product_id, collection) {
    const def = shop_product_id in this.cache_def
      ? this.cache_def[shop_product_id]
      : new $.Deferred();

    this.cache[shop_product_id] = [];
    collection.each((model) => {
      if (
        _.isArray(model.get('shop_product_ids'))
					&& model.get('shop_product_ids').indexOf(shop_product_id) !== -1
      ) {
        this.cache[shop_product_id].push(model);
      }
    });

    return this.resolveWithShopProduct(def, shop_product_id);
  },

  fetchShopProductAddonForId(shop_product_id) {
    const def = new $.Deferred();

    if (shop_product_id in this.cache) {
      this.resolveWithShopProduct(def, shop_product_id);
    } else if (
      shop_product_id in this.cache_def
				&& this.cache_def[shop_product_id].state() === 'pending'
    ) {
      // not in the list, but waiting on response
      this.cache_def[shop_product_id].then(
        def.resolve,
        def.reject,
      );
    } else {
      this.cache_def[shop_product_id] = def;
      const shopProductAddonGroupModel = new ShopProductAddonGroupModel();
      const parameters = {
        shop_product_ids: [shop_product_id],
        lang: Locale.getLocale(),
      };

      shopProductAddonGroupModel.getForShopProductIds(parameters)
        .then((resp) => {
          // this one will resolve the def stored in this.cache_def[shop_product_id]
          const collection = this.prepareResponseData(resp.data);
          this.setShopProductAddonsFromPreparedCollection(shop_product_id, collection);
        }, def.reject);
    }
    return def.promise();
  },

}));
