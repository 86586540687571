define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',

  'modules/shop.cash-register-retail/acls/settings',

  'modules/shop.cash-register-retail/views/popups/simplePopup',
  'modules/shop.cash-register-retail/views/popups/pointOfSalePopup',

  'modules/shop.common/components/mode',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone,
  PointOfSaleSetting,
  SettingsAcl,
  SimplePopup, PointOfSalePopup,
  ModeComponent, HistoryBreadcrumb,
) => ({

  hasPointOfSale() {
    return PointOfSaleSetting.has('id');
  },

  renderPointOfSalePopup() {
    const view = new PointOfSalePopup();

    // setup popup
    const popup = new SimplePopup({
      content: view,
      headerCancelEnabled: false,
      footerConfirmEnabled: false,
      footerCancelEnabled: false,
    });

    popup.open();

    view.on('shop::selected', () => {
      this.reloadCurrentRoute();
      popup.close();
    });
  },

  pointOfSaleCheck() {
    if (
      (!ModeComponent.isInPreviewMode() && !this.hasPointOfSale()) // NOT in preview mode while having no POS set
				|| ModeComponent.isInWebMode() // WEB mode
    ) {
      this.renderPointOfSalePopup();
    }
  },

  reloadCurrentRoute() {
    const route = HistoryBreadcrumb.getCurrent();
    Backbone.history.navigate(`reload/${route}`, { trigger: true });
  },

  getRegion() {
    // get and creat el's
    const $body = $('body');
    const $region = $('<div>')
      .addClass('remove-me-on-route-change');

    // setup region
    $body.append($region);
    return new Backbone.Marionette.Region({
      el: $region,
    });
  },

  cleanRegion(region) {
    region.reset();
    region.options.el.remove();
  },

}));
