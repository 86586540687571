define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
], (
  $, _, Backbone, Component,
) => {
  const PinReceiptComponent = Component.extend({
    PIN_RECEIPT_PREFIX_CCV_V1: '__TypeCCVPinJSON_V1__',

    getCCVPinReceipts(pinReceipt) {
      if (_.isString(pinReceipt) && pinReceipt.startsWith(this.PIN_RECEIPT_PREFIX_CCV_V1)) {
        return JSON.parse(
          // JSON pin receipt without the prefix.
          pinReceipt.slice(this.PIN_RECEIPT_PREFIX_CCV_V1.length),
        );
      }

      // No CCV receipt
      return null;
    },
  });

  return new PinReceiptComponent();
});
