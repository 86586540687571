define([
  'modules/profile/models/profile',
  'modules/common/components/historyBreadcrumb',
  'modules/common/components/locale',
  'application',
], (
  ProfileModel, HistoryBreadcrumb, Locale, App,
) => ({

  init(KEY) {
    // the
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v; let w; let x; let y; let z; o = p[d] = p[d] || {}; o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          }(v[w]));
        }
        y = e.createElement(n); y.async = !0; y.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      }(window, document, 'script', 'pendo'));
    }(KEY));
  },

  setUser() {
    const member = ProfileModel.getProductWithMember('CommerceModule', 'Commerce');
    let role = null;
    if (member && member.membership) {
      role = member.membership.group_member_type.alias;
    }
    pendo.initialize({
      visitor: {
        id: ProfileModel.get('relation_data_profile.shortname'),
        email: ProfileModel.getProfileEmail(),
        full_name: ProfileModel.getFullProfileName(),
        role,
        app_locale: Locale.getLocale(),
      },

      account: {
        id: App.settings.upx.account,
        name: ProfileModel.getProfileCompanyName(),

        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
      },
    });
  },
}));
