define([
  'upx.modules/ShopModule/models/TableOrder',
], (
  TableOrderModel,
) => ({

  tableOrderMap: {},

  async loadById(id, forceNew) {
    if (!this.tableOrderMap[id] || forceNew) {
      const tableOrderModel = new TableOrderModel({
        id,
        options: {
          get_items: true,
          format_subitems: true,
          get_parts: true,
        },
      });
      await tableOrderModel.fetchPromise();

      this.tableOrderMap[id] = tableOrderModel;
    }

    return this.getById(id);
  },

  getById(id) {
    if (id in this.tableOrderMap) {
      return this.tableOrderMap[id].clone();
    }
  },

  clearById(id) {
    delete this.tableOrderMap[id];
  },

}));
