define([
  'jquery',
  'underscore',
  'modules/common/components/locale',
  'toastr',

  'upx.modules/BillingModule/models/Invoice',
  'upx.modules/RelationsModule/collections/Person',
  'modules/shop.cash-register-retail/views/popups/emailInvoicePopup',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/components/contactPersonRole.js',
  'modules/common/components/promisify',

], ($, _, Locale, Toastr,
  InvoiceModel, PersonCollection, EmailInvoicePopup, DefaultShopConfigurationModel,
  ContactPersonRole, Promisify) => ({

  buildEmail(email, name = null) {
    return {
      email,
      name,
    };
  },

  getInitialEmails(initialEmail, invoiceModel, persons) {
    const possibleEmails = [
      this.buildEmail(initialEmail),
    ];
    if (invoiceModel) {
      possibleEmails.push(this.buildEmail(invoiceModel.get('address_to.address_billing.contact_set.email')));
      possibleEmails.push(this.buildEmail(invoiceModel.get('address_to.contact_address.contact_set.email')));
      possibleEmails.push(this.buildEmail(invoiceModel.get('address_to.contact_set.email')));
    }
    if (persons) {
      persons.each((model) => {
        let name = `${model.get('firstname') || ''} ${model.get('familyname_prefix') || ''} ${model.get('familyname') || ''}`;
        name = name.trim();
        const role = ContactPersonRole.getTranslatedRole(model.get('position'));

        if (role) {
          if (name !== '') {
            name += ` (${role})`;
          } else {
            name += role;
          }
        }
        name = name.trim();
        possibleEmails.push(this.buildEmail(
          model.get('contact_set.email'),
          name !== '' ? name : null,
        ));
      });
    }
    return possibleEmails;
  },
  async sendByIdAsync({
    invoiceId,
    initialEmail = null,
  }) {
    try {
      const invoiceModel = new InvoiceModel({
        id: invoiceId,
      });

      await invoiceModel.fetchPromise();
      let initialEmails = [
        this.buildEmail(initialEmail),
      ];
      if (
        invoiceModel.get('relation_data_to_id')
          && invoiceModel.get('relation_data_from_id') !== invoiceModel.get('relation_data_to_id')
      ) {
        // not anonymous
        const personCollection = new PersonCollection();
        await personCollection.fetchPromise({
          params: {
            start: 0,
            limit: 0,
            filters: [{
              name: 'relation_data_id__=',
              val: invoiceModel.get('relation_data_to_id'),
            }, {
              name: 'contact_set/email__notnull',
              val: '1',
            }],
          },
        });
        initialEmails = this.getInitialEmails(initialEmail, invoiceModel, personCollection);
      }

      const view = new EmailInvoicePopup({
        emails: initialEmails,
      });
      const email = await view.openPromise();
      await this.emailInvoicePromise(invoiceModel, email);
    } catch (e) {
      Toastr.error(Locale.translate('email_failed_to_send'));
      throw e;
    }
    Toastr.success(Locale.translate('email_has_been_sent'));
  },

  sendById({
    invoiceId,
    initialEmail = null,
    def = null,
  }) {
    def = def || new $.Deferred();
    this.sendByIdAsync({ invoiceId, initialEmail })
      .then(def.resolve)
      .catch(def.reject);
    return def.promise();
  },

  emailInvoice(invoiceModel, targetEmail) {
    const invoiceParams = {
      fields: {
        id: invoiceModel.get('id'),
        receiver: {
          email: targetEmail,
        },
        sender: {
          email: DefaultShopConfigurationModel.getRelation().get('contact_set.email'),
        },
        email_body: '',
        is_reminder: false,
      },
    };

    return invoiceModel.send(invoiceParams);
  },

  async emailInvoicePromise(...args) {
    const deferred = this.emailInvoice(...args);
    return Promisify.deferredToPromise(deferred);
  },
}));
