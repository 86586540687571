define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'toastr',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, Component, Toastr, CashRegisterApi,
) => {
  const Toaster = Component.extend({

    toastr: Toastr,

    initialize() {
      this.toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        onclick: null,
        showDuration: '4000',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
      };
    },

    clear() {
      this.toastr.clear();
    },

    info(description, title, optionsOverride) {
      CashRegisterApi.logAction('TOASTER_SHOWN', {
        lev: 'info',
        description,
        title,
      });
      this.toastr.info(description, title, optionsOverride);
    },

    warning(description, title, optionsOverride) {
      CashRegisterApi.logAction('TOASTER_SHOWN', {
        lev: 'warning',
        description,
        title,
      });
      this.toastr.warning(description, title, optionsOverride);
    },

    error(description, title, optionsOverride) {
      CashRegisterApi.logAction('TOASTER_SHOWN', {
        lev: 'error',
        description,
        title,
      });
      this.toastr.error(description, title, optionsOverride);
    },

    success(description, title, optionsOverride) {
      CashRegisterApi.logAction('TOASTER_SHOWN', {
        lev: 'success',
        description,
        title,
      });
      this.toastr.success(description, title, optionsOverride);
    },

  });

  return new Toaster();
});
