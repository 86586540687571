define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',
], (
  $, _, Backbone,
  Locale, NaturalSearchShopFlatProductCollection,
) => ({

  /**
   * key = category_id
   * value = collection of category models
   */
  cache_by_id: [],
  /**
     * key = category_id
     * value = collection of category models
     */
  cache_active_by_id: [],

  // = (6x) 50 rows in frontend, for now we do not have any search,
  // 300 products is the max for now so it's still usuable
  limit: 300,

  getProductsByCategory(category_id) {
    const def = new $.Deferred();

    if (this.cache_by_id[category_id]) {
      // already cached, resolve collection
      def.resolve(this.cache_by_id[category_id]);
    } else {
      // not cached yet, let's fetch the products by category
      const product_collection = new NaturalSearchShopFlatProductCollection();

      product_collection
        .fetch({ params: this.getParameters(category_id) })
        .then((response) => {
          if (response.total > this.limit) {
            console.error(`[ProductCategoryCache] - limit of categories is reached (category_id: ${category_id}), only ${this.limit} loaded, available: ${response.total}`);
          }

          this.cache_by_id[category_id] = product_collection;
          def.resolve(this.cache_by_id[category_id]);
        }, def.reject);
    }

    return def;
  },
  getActiveProductsByCategory(category_id) {
    const def = new $.Deferred();

    if (this.cache_active_by_id[category_id]) {
      def.resolve(this.cache_active_by_id[category_id]);
    } else {
      const product_collection = new NaturalSearchShopFlatProductCollection();

      product_collection
        .fetch({ params: this.getParameters(category_id, true) })
        .then((response) => {
          if (response.total > this.limit) {
            console.error(`[ProductCategoryCache] - limit of categories is reached (category_id: ${category_id}, active=true), only ${this.limit} loaded, available: ${response.total}`);
          }

          this.cache_active_by_id[category_id] = product_collection;
          def.resolve(this.cache_active_by_id[category_id]);
        }, def.reject);
    }

    return def;
  },

  getParameters(category_id, active = false) {
    const filters = [{
      name: 'flat_product/product/type__in_list',
      multi_val: [
        'configurable_assign',
        'simple',
      ],
    }, {
      name: 'flat_product/product/attribute_set_id__notnull',
      val: true,
    }, {
      name: 'category_id__=',
      val: category_id,
    }, {
      name: 'product_price/ppu_wt__notnull',
      val: true,
    }];
    if (active) {
      filters.push({
        name: 'active',
        val: true,
      });
    }
    return {
      query: 0,
      lang: Locale.getLocale(),
      start: 0,
      limit: this.limit,
      sort: [{
        name: 'flat_product/title',
        dir: 'asc',
      }],
      filters,
    };
  },
}));
