define([
  'jquery',
  'underscore',

  'modules/shop.cash-register-retail/components/environment',
  'modules/common/components/component',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/common/components/promisify',
  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/collections/loadable/productionGroups',
  'modules/shop.cash-register-retail/collections/loadable/tables',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/printing',
  'upx.modules/ShopModule/models/TableOrderPrintout',
  'upx.modules/ShopModule/models/TableOrderPart',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/shop.cash-register-retail/models/printables/kitchenTablePrintable',
  'modules/shop.cash-register-retail/models/settings/receiptPrinterLoader',
], (
  $, _,
  Environment, Component, Locale, Moment, Promisify, Profile, CashRegisterApi,
  ProductionGroups, Tables, DeviceConfig, ReceiptPrinter, Printing, TableOrderPrintoutModel, TableOrderPartModel,
  ShopConfiguration, Currency, Toaster,
  Printable, ReceiptPrinterLoader,
) => {
  const ProductionGroupPrint = Component.extend({

    TYPE_TEST: 'test',
    TYPE_DUPLICATE: 'dupe',
    TYPE_PRODUCTION: 'production',
    TYPE_ANNOUNCEMENT: 'announcement',

    initialize() {
      this.loadPrinterModels();
    },

    loadPrinterModels() {
      const typesToLoad = [
        ReceiptPrinter.PRINTER_TYPE_ESCPOS,
        ReceiptPrinter.PRINTER_TYPE_ESCPOS_NETWORK,
      ];
      this.loadedPrinterModels = {};

      for (const type of typesToLoad) {
        this.loadedPrinterModels[type] = ReceiptPrinterLoader.loadReceiptPrinterModel(type);
      }
    },

    printJsonData(printerId, printerType, jsonData) {
      const def = $.Deferred();

      if (!(printerType in this.loadedPrinterModels)) {
        def.reject(`Printer model not loaded for type ${printerType}`);
      } else {
        const printer = this.loadedPrinterModels[printerType];

        printer.printJsonData(jsonData, printerId).then(def.resolve, def.reject);
      }

      return Promisify.deferredToPromise(def);
    },

    async printTest(printerId, printerType, productionGroupId) {
      const order_items = [
        {
          quantity: 99,
          name: `${Locale.translate('product')} 1`,
          description: Locale.translate('description'),
          subitems: [
            {
              name: `${Locale.translate('option')} 1`,
            },
            {
              name: `${Locale.translate('option')} 2`,
            },
          ],
        },
        {
          quantity: 1,
          name: `${Locale.translate('product')} 2`,
        },
      ];

      const data = this.getHtmlData({ productionGroupId, order_items, direct_number: 999 });
      data.table_name = Locale.translate('table_01_zone_a');
      data.created_by_name = Profile.getFullProfileName();

      return this.printType(printerId, printerType, data, this.TYPE_TEST);
    },

    async printType(printerId, printerType, data, type) {
      data.type = type;
      if (type === this.TYPE_TEST) {
        data.type_name = Locale.translate('test');
      } else if (type === this.TYPE_DUPLICATE) {
        data.type_name = Locale.translate('dupe');
      } else if (type === this.TYPE_ANNOUNCEMENT) {
        data.type_name = Locale.translate('announcement');
      } else if (type === this.TYPE_PRODUCTION) {
        data.type_name = Locale.translate('production');
      } else {
        throw new Error(`Unknown type: ${type}`);
      }

      const jsonData = new Printable().build(data);
      await this.printJsonData(printerId, printerType, jsonData);
      return jsonData;
    },

    getHtmlData({
      productionGroupId, table_name = null, created_by_name = null, created_date = new Date(), order_items = [], direct_number = null,
    }) {
      // Ensure production group
      const productionGroupModel = ProductionGroups.get(productionGroupId);
      if (!productionGroupModel) {
        throw new Error(`Production group ${productionGroupId} was not loaded`);
      }
      const group_name = productionGroupModel.get('name');
      order_items = order_items || [];

      return {
        group_name,
        table_name,
        direct_number,
        created_by_name: created_by_name || '',
        created_date: Moment(created_date).format('lll'),
        order_items,
      };
    },

    getProductionGroupPrinters() {
      let productionGroupPrinters = {};

      const configModel = DeviceConfig.getConfig(DeviceConfig.PATH_ProductionGroupPrinters);

      if (configModel) {
        const configData = configModel.get('extra');

        if (Array.isArray(configData)) {
          // Because of some weird conversion issue. The "extra" field in the config is an array instead of an object.
          // We need to convert it to an object. So that's what we do here.

          for (let i = 0; i < configData.length; i++) {
            if (configData[i] !== undefined) {
              productionGroupPrinters[i] = configData[i];
            }
          }
        } else {
          productionGroupPrinters = configData;
        }

        let updatedStructure = false;
        // If the printers are still in the old structure, we need to convert it to the new structure.
        for (const [key, value] of Object.entries(productionGroupPrinters)) {
          const oldPrinter = typeof value === 'string';

          if (oldPrinter) {
            productionGroupPrinters[key] = {
              id: value,
              type: ReceiptPrinter.PRINTER_TYPE_ESCPOS, // All old printers are PRINTER_TYPE_ESCPOS
            };
            updatedStructure = true;
          }
        }

        if (updatedStructure) {
          DeviceConfig.setConfig(DeviceConfig.PATH_ProductionGroupPrinters, productionGroupPrinters);
        }
      }

      return productionGroupPrinters;
    },

    getProductionGroupPrinter(productionGroupId) {
      const productionGroupPrinters = this.getProductionGroupPrinters();
      const printer = productionGroupPrinters[productionGroupId];

      return {
        id: printer ? printer.id : ReceiptPrinter.get('id'),
        type: printer ? printer.type : ReceiptPrinter.get('type'),
      };
    },

    async printFromTableOrderPartAnnouncement(tableId, tableOrderPartObject) {
      return this.printFromTableOrderPart(tableId, tableOrderPartObject, this.TYPE_ANNOUNCEMENT);
    },

    async printFromTableOrderPartProduction(tableId, tableOrderPartObject) {
      return this.printFromTableOrderPart(tableId, tableOrderPartObject, this.TYPE_PRODUCTION);
    },

    async printFromTableOrderPartDuplicate(tableId, tableOrderPartObject) {
      return this.printFromTableOrderPart(tableId, tableOrderPartObject, this.TYPE_DUPLICATE);
    },

    printTableSummary(tableModel, tableOrderItems) {
      return new Promise((resolve, reject) => {
        if (!tableModel) {
          throw new Error('Failed to load table');
        }

        const data = {
          total_wt: tableModel.get('table_order.value_wt'),
          currency_iso3: ShopConfiguration.getCurrencyIso3(),
          name: this.getTableName(tableModel.get('id')),
          print_person: tableModel.get('table_order.created_by.name'),
          print_time: new Date().getTime(),
          order_items: tableOrderItems.filter((item) => item.quantity > 0), // Filter out removed items
        };
        Printing.printTableOrder(data).then(resolve, reject);
      });
    },

    getTableName(tableId) {
      const tableModel = Tables.get(tableId);
      let table_name = '';
      if (tableModel) {
        table_name = `${tableModel.get('name')} (${tableModel.get('table_area.name')})`;
      }
      return table_name;
    },

    async printFromTableOrderPart(tableId, tableOrderPartObject, type) {
      const {
        created_by, date_created, table_order_items, table_order_id, created_by_id,
      } = tableOrderPartObject;
      return this.printOrder({
        type,
        table_name: this.getTableName(tableId),
        created_by_name: created_by.name || '',
        created_by_id,
        date_created,
        table_order_items,
        table_order_id,
      });
    },

    async printDirectOrder(order_number, order_items) {
      return this.printOrder({
        type: this.TYPE_PRODUCTION,
        created_by_name: Profile.getFullProfileName(),
        direct_number: order_number.substr(-3),
        table_order_items: order_items,
      });
    },

    incrementTableOrderPartPrintCount(order_part_id) {
      const tableOrderPartModel = new TableOrderPartModel({ id: order_part_id });
      return Promisify.deferredToPromise(
        tableOrderPartModel.incrementPrintCount(),
      );
    },

    async printOrder({
      type,
      table_name = null,
      created_by_name = null,
      created_by_id = null,
      date_created = new Date(),
      table_order_items = [],
      table_order_id = null,
      direct_number = null,
    }) {
      const productionGroupItemMap = {};
      table_order_items.forEach((orderItem) => {
        const { production_group_id, quantity } = orderItem;

        // Skip item with no quantity
        if (quantity === 0) return;

        const add_to_group_ids = [];
        if (production_group_id) {
          add_to_group_ids.push(production_group_id);
          const subItems = orderItem.subitems || [];
          for (const i in subItems) {
            const subItem = subItems[i];
            if (subItem.production_group_id) {
              if (add_to_group_ids.indexOf(subItem.production_group_id) === -1) {
                add_to_group_ids.push(subItem.production_group_id);
              }
            }
          }
        }

        if (add_to_group_ids.length > 0) {
          for (let i = 0; i < add_to_group_ids.length; i++) {
            const group_id = add_to_group_ids[i];
            if (!_.isArray(productionGroupItemMap[group_id])) {
              productionGroupItemMap[group_id] = [];
            }
            productionGroupItemMap[group_id].push(orderItem);
          }
        }
      });

      const promises = [];
      _.each(productionGroupItemMap, (order_items, productionGroupId) => {
        const promise = new Promise(async (resolve, reject) => {
          try {
            const productionGroupModel = ProductionGroups.get(productionGroupId);
            if (!productionGroupModel) {
              throw new Error(`Production group ${productionGroupId} was not loaded`);
            }

            const printer = this.getProductionGroupPrinter(productionGroupId);
            const should_announce = productionGroupModel.get('is_announcement_printout');

            let html = null;
            const htmlData = this.getHtmlData({
              productionGroupId,
              table_name,
              created_by_name,
              date_created,
              order_items,
              direct_number,
            });
            if (type === this.TYPE_ANNOUNCEMENT) {
              if (should_announce) {
                html = await this.printType(printer.id, printer.type, htmlData, type);
              }
            } else {
              html = await this.printType(printer.id, printer.type, htmlData, type);
            }

            if (html) {
              // Register print
              const orderItem = order_items[0] || {};
              const tableOrderPrintoutModel = new TableOrderPrintoutModel({
                table_order_id,
                table_order_part_id: orderItem.table_order_part_id || null,
                production_group_id: productionGroupId,
                date_printed: new Moment().format(),
                created_by_id,
                print_html: html,
              });
              await tableOrderPrintoutModel.savePromise();
            }

            resolve();
          } catch (err) {
            console.error(err);
            reject(err);
          }
        });

        // Promises...
        promises.push(promise);
      });

      await Promise.all(promises);
    },

  });
  const obj = new ProductionGroupPrint();
  if (Environment.isDevelopment()) {
    window.ProductionGroupPrint = obj;
  }
  return obj;
});
