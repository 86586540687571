define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/common/components/component',
], (
  $, _, Backbone,
  Locale, Component,

) => {
  const TYPE_PICK_AT_STORE = 'PickupAtStore';
  const TYPE_PARCEL = 'Parcel';
  const TYPE_TRUCK_DELIVERY = 'TruckDelivery';
  const TYPE_OTHER = 'Other';
  const TYPE_SAME_DAY = 'SameDay';

  const Comp = Component.extend({
    TYPE_PICK_AT_STORE,
    TYPE_PARCEL,
    TYPE_TRUCK_DELIVERY,
    TYPE_OTHER,
    TYPE_SAME_DAY,

    getTranslatedLabelByAlias(alias) {
      switch (alias) {
        case this.TYPE_PICK_AT_STORE:
          return Locale.translate('pickup_at_store');
        case this.TYPE_PARCEL:
          return Locale.translate('parcel');
        case this.TYPE_TRUCK_DELIVERY:
          return Locale.translate('truck_delivery');
        case this.TYPE_SAME_DAY:
          return Locale.translate('same_day_delivery');
        case this.TYPE_OTHER:
          return Locale.translate('other');
      }
      console.error('No label shipment type alias defined', alias);
      return '';
    },

    getIconForType(alias) {
      switch (alias) {
        case this.TYPE_PICK_AT_STORE:
          return 'fas fa-store-alt';
        case this.TYPE_PARCEL:
          return 'fas fa-box';
        case this.TYPE_TRUCK_DELIVERY:
          return 'fas fa-truck';
        case this.TYPE_SAME_DAY:
          return 'fas fa-shipping-fast';
        case this.TYPE_OTHER:
          return 'fas fa-question';
      }
      console.error('No icon shipment type alias defined', alias);
      return '';
    },
  });
  return new Comp();
});
