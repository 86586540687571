define([
  'jquery',
  'modules/common/components/promisify',
  'modules/upx/components/upx',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.cash-register-retail/models/settings/paymentMethods.js',

  'modules/common/components/currency',
  'modules/shop.cash-register-retail/collections/TaxRate.js',
], ($, Promisify, Upx, Locale, ShopPos, PaymentMethods,
  Currency, TaxRateCollection) => ({

  isConfiguredValue: null,

  async isConfigured() {
    if (this.isConfiguredValue === null) {
      const configList = await Promisify.deferredToPromise(
        Upx.call(
          'FiskalyModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'configured__=',
              val: '1',
            }],
          },
        ),
      );
      this.isConfiguredValue = configList.count === 1;
    }
    return this.isConfiguredValue;
  },

  sumPayments(paymentMethodCollection) {
    let isOnInvoice = false;
    let isOnOrder = false;
    let cash = '0.00';
    let non_cash = '0.00';
    paymentMethodCollection.each((model) => {
      const id = model.get('id');
      const ppu_wt = model.get('ppu_wt');
      if (id === PaymentMethods.CASH_METHOD) {
        cash = Currency.Math.add(ppu_wt, cash);
      } else if (id === PaymentMethods.INVOICE_METHOD) {
        isOnInvoice = true;
      } else if (id === PaymentMethods.PAYLATER_METHOD) {
        isOnOrder = true;
      } else {
        non_cash = Currency.Math.add(ppu_wt, non_cash);
      }
    });
    const change = paymentMethodCollection.getSpareChangeWt();
    if (change !== '0.00') {
      cash = Currency.Math.subtract(cash, change);
    }
    const payment_types = {
      cash,
      non_cash,
    };
    return { isOnInvoice, isOnOrder, payment_types };
  },
  sumTaxRates(rates) {
    let standard = '0.00';
    let reduced = '0.00';
    let zero = '0.00';

    rates.forEach((rateTotal) => {
      const { tax_rate_id, row_total_wt } = rateTotal;
      const rate = TaxRateCollection.get(tax_rate_id);
      const total = Currency.currencyToFloat(row_total_wt).toFixed(2);
      if (!rate) {
        throw new Error(`Cannot process tax rate id from receipt: ${tax_rate_id} -> Not Found`);
      }
      const countryIso2 = rate.get('country_iso2');
      if (rate.get('value') === 0) {
        zero = Currency.Math.add(total, zero);
      } else if (countryIso2 === 'DE') {
        // we support germany only now
        const alias = rate.get('alias');
        if (alias === 'standard') {
          standard = Currency.Math.add(total, standard);
        } else if (alias === 'reduced_books_food_hotels_medical') {
          reduced = Currency.Math.add(total, reduced);
        } else {
          throw new Error(`Cannot process tax rate id from receipt: Unknown country=${countryIso2} alias=${alias}`);
        }
      } else {
        throw new Error(`Cannot process tax rate id  from receipt: ${tax_rate_id}`);
      }
    });

    return {
      standard,
      reduced,
      zero,
    };
  },
  async signOrder(
    order, processingView, payment_ids, paymentMethodCollection, forceOnOrder = false,
  ) {
    if (!await this.isConfigured()) {
      // nothing to sign -> no configuration
      return null;
    }
    const log = processingView.signing();
    log.setProcessingStatus();

    let data = null;
    let qr = null;
    let error = null;
    try {
      const { isOnOrder, payment_types } = this.sumPayments(paymentMethodCollection);
      const tax_rates = this.sumTaxRates(order.get('order_taxes') || []);
      const fields = {
        order_id: order.get('id'),
        shop_id: ShopPos.getShopId(),
        payment_ids,
        tax_rates,
        payment_types,
      };

      const method = isOnOrder || forceOnOrder ? 'registerOpenOrder' : 'registerOrderWithTurnover';
      if (forceOnOrder) {
        console.warn('Receipt signing: force on order', fields);
      }
      const signed = await Promisify.deferredToPromise(
        Upx.call(
          'FiskalyModule', method,
          { fields },
        ),
      );
      data = signed.receipt_data;
      qr = signed.qr_code;
      log.success();
    } catch (e) {
      error = Locale.translate('failed_to_generate_receipt_signature');
      log.error(error);
      console.error(
        'Failed to generate receipt signature',
        {
          order_id: order.get('id'),
          order_number: order.get('number'),
        },
        e,
      );
    }
    return {
      data,
      error,
      qr,
    };
  },

  async signInvoicePayments(invoice, processingView, payment_ids, paymentMethodCollection) {
    if (!await this.isConfigured()) {
      // nothing to sign -> no configuration
      return null;
    }
    const log = processingView.signing();
    log.setProcessingStatus();

    let data = null;
    let qr = null;
    let error = null;
    try {
      const { payment_types } = this.sumPayments(paymentMethodCollection);
      const tax_rates = this.sumTaxRates(invoice.get('invoice_taxes') || []);
      const fields = {
        invoice_id: invoice.get('id'),
        shop_id: ShopPos.getShopId(),
        payment_ids,
        tax_rates,
        payment_types,
      };

      const signed = await Promisify.deferredToPromise(
        Upx.call(
          'FiskalyModule', 'registerInvoicePayments',
          { fields },
        ),
      );
      data = signed.receipt_data;
      qr = signed.qr_code;
      log.success();
    } catch (e) {
      error = Locale.translate('failed_to_generate_receipt_signature');
      log.error(error);
      console.error(
        'Failed to generate receipt signature',
        {
          invoice_id: invoice.get('id'),
          invoice_number: invoice.get('number'),
        },
        e,
      );
    }
    return {
      data,
      error,
      qr,
    };
  },
}));
