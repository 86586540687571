define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, CashRegisterApi,
) => ({

  createTimer(name, initialData) {
    const def = new $.Deferred();
    const start_time = new Date().getTime();

    def.then((resolveData) => {
      this.postResult(
        true,
        name,
        initialData, resolveData,
        new Date().getTime(),
        start_time,
      );
    }, (rejectData) => {
      this.postResult(
        false,
        name,
        initialData, rejectData,
        new Date().getTime(),
        start_time,
      );
    });

    return def;
  },

  postResult(success, name, initialData, resultData, end_time, start_time) {
    const resolve_time = {
      name,
      success,
      initial_data: initialData,
      result: resultData,
      total_time_ms: end_time - start_time,
    };
    this.postToApi(resolve_time);
  },

  postToApi(post_data) {
    CashRegisterApi.log('profiling', 'PROFILING', post_data)
      .fail((errorResponse) => {
        // Make sure if call to the CashRegisterApi fails
        console.warnOriginal('Failed to log the "profiling"', post_data, errorResponse);
      });
  },

}));
