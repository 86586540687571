define([
  'jquery',
  'jquery-timepicker/jquery.timepicker',
  'jquery-timepicker/jquery.timepicker.css',
], (
  $,
) => ({
  applyToEl($el, options = {}) {
    let defaults = {
      timeFormat: 'HH:mm',
      dynamic: true,
      dropdown: true,
      scrollbar: true,
      zindex: 2000,
    };
    defaults = $.extend(defaults, options);
    $el.timepicker(defaults);
  },

}));
