define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/components/productCache',

  'upx.modules/ShopModule/models/ShopFlatProduct',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
], (
  $, _, Backbone, Component,
  ProductCache,
  ShopFlatProductModel,
  FeaturedAttributeCollection,
) => {
  const c = Component.extend({
    RESULT_BACKEND: 'backend',
    RESULT_CACHE: 'cache',

    digitRegex: /^\d+$/,

    searchProduct(query) {
      const self = this;
      const def = new $.Deferred();

      if (query.match(this.digitRegex)) {
        this.searchProductInCache(query).then(def.resolve, () => {
          self.searchProductByBarcode(query).then(def.resolve, def.reject);
        });
      } else {
        self.searchProductByQuery(query).then(def.resolve, def.reject);
      }

      return def;
    },

    searchProductInCache(query) {
      const def = new $.Deferred();
      ProductCache.findByBarcode(query)
        .then((model) => {
          def.resolve({
            mode: this.RESULT_CACHE,
            model: new ShopFlatProductModel(model.get('shop_flat_product')),
          });
        }, def.reject);

      return def;
    },

    naturalSearch(params, searchMessage) {
      const shopFlatProductModel = new ShopFlatProductModel();
      const def = new $.Deferred();
      shopFlatProductModel.naturalSearch(params)
        .then((searchResponse) => {
          if (searchResponse.total > 1) {
            console.warn(`Multiple products found for ${searchMessage}`);
            def.reject();
          } else if (searchResponse.total === 0) {
            console.warn(`No products found for ${searchMessage}`);
            def.reject();
          } else {
            // searchResponse.total === 1
            const dataArray = searchResponse.data;
            const first = _.first(dataArray);
            const model = new ShopFlatProductModel(first);

            ProductCache.storeShopProduct(model);
            def.resolve({
              mode: this.RESULT_BACKEND,
              model,
            });
          }
        }, def.reject);

      return def.promise();
    },
    searchProductByBarcode(barcode) {
      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit: 1,
        filters: [{
          name: 'product_price/ppu__notnull',
          val: '1',
        }],
      };

      const barcodeAttr = FeaturedAttributeCollection.getAttributeByAlias(FeaturedAttributeCollection.ALIAS_BARCODE);
      if (!barcodeAttr) {
        console.warn('No barcode attr is set in FeaturedAttributeCollection');
      }
      if (barcodeAttr && barcode.match(this.digitRegex)) {
        const barcodes = [];
        const possibleBarcodes = ProductCache.getPossibleBarcodes(barcode);
        if (possibleBarcodes !== false) {
          possibleBarcodes.forEach((possibleBarcode) => {
            barcodes.push(`${barcodeAttr.get('attribute.name')}:${possibleBarcode}`);
          });
        }
        // barcode search
        params.filters.push({
          name: 'flat_product/content_var_items__overlap',
          multi_val: barcodes,
        });
      } else {
        params.query = barcode;
      }
      return this.naturalSearch(params, barcode);
    },

    searchProductByQuery(query) {
      const params = {
        query,
        lang: 0,
        start: 0,
        limit: 1,
        filters: [{
          name: 'product_price/ppu__notnull',
          val: '1',
        }],
      };
      return this.naturalSearch(params, `query: ${query}`);
    },
  });
  return new c();
});
