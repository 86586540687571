define([
], (
) => ({

  refundOrderId: null,

  set(id) {
    this.refundOrderId = id;
  },

  get() {
    return this.refundOrderId;
  },

  has() {
    if (this.refundOrderId) {
      return true;
    }
    return false;
  },

  clear() {
    this.refundOrderId = null;
  },

}));
