define([
  'jquery',
  'underscore',
  'backbone',
  'markdown-it',
  '@storekeeper/escpos',

  'modules/shop.cash-register-retail/templates/printables/priceSticker.hbs',
  'modules/shop.cash-register-retail/templates/printables/breadSticker.hbs',
  'modules/shop.cash-register-retail/templates/printables/priceTag.hbs',
  'modules/shop.cash-register-retail/templates/printables/content.hbs',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',

  'modules/shop.cash-register-retail/models/settings/stickerPrinter',
  'modules/shop.cash-register-retail/models/settings/mediaType',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
  'modules/common/collections/countries',
  'upx.modules/ShopModule/models/ShopLedgerOperation',
  'upx.modules/RelationsModule/models/Relation',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/shippingMethod.js',
  'modules/shop.cash-register-retail/components/email.js',
  'modules/shop.cash-register-retail/components/feature',

  // EscPos printables
  'modules/shop.cash-register-retail/models/printables/receiptPrintable',
  'modules/shop.cash-register-retail/models/printables/tableSummaryPrintable',
  'modules/shop.cash-register-retail/models/printables/tabSummaryPrintable',
  'modules/shop.cash-register-retail/models/printables/kitchenReceiptPrintable',
  'modules/shop.cash-register-retail/models/printables/invoiceReceiptPrintable',
  'modules/shop.cash-register-retail/models/printables/repairReceiptPrintable',

  'modules/shop.cash-register-retail/models/printables/dailyReturnsPrintable',
  'modules/shop.cash-register-retail/models/printables/onlineFoodOrderKitchenReceiptPrintable',
  'modules/shop.cash-register-retail/models/printables/onlineFoodDeliveryReceiptPrintable',

  'modules/shop.cash-register-retail/models/printables/packingSlipPrintable',
  'modules/shop.cash-register-retail/models/printables/mutationPrintable',
  'modules/shop.cash-register-retail/models/printables/dailyTurnoverPrintable',
  'modules/shop.cash-register-retail/models/printables/shipmentContentsPrintable',

  'modules/shop.cash-register-retail/models/printables/shipmentDeliveryPrintable',
  'modules/shop.cash-register-retail/models/printables/cashupPrintable',
  'modules/shop.cash-register-retail/models/printables/ccvPrintable',

  'modules/shop.cash-register-retail/components/orderReceipt',
  'modules/shop.cash-register-retail/components/paymentMethodItem',

  'jsbarcode',
], (
  $, _, Backbone, MarkdownIt, EscPos,
  PriceStickerTemplate, BreadStickerTemplate, PriceTagTemplate, ContentTemplate,
  DefaultShopConfigurationModel, ProfileModel, ReceiptPrinterModel, PriceTagPrinterModel,
  StickerPrinterModel, MediaTypeModel, ShopPosSetting,
  FeaturedAttributeCollection, CountryCollection, ShopLedgerOperationModel, RelationModel, LoyaltyProgramModel, SelectedCustomer,
  DeviceConfig, PointOfSaleModel, Currency, Locale, Moment, ShippingMethod, Email, Feature,
  ReceiptPrintable, TableSummaryPrintable, TabSummaryPrintable, KitchenReceiptPrintable, InvoiceReceiptPrintable, RepairReceiptPrintable,
  DailyReturnsPrintable, OnlineFoodOrderKitchenPrintable, OnlineFoodDeliveryPrintable,
  PackingSlipPrintable, MutationPrintable, DailyTurnoverPrintable, ShipmentContentsPrintable,
  ShipmentDeliveryPrintable, CashupPrintable, CCVPrintable,
  OrderReceipt, PaymentMethodItemComponent,
) => ({

  logoData: null,
  relationCache: {},

  formatOrderItems(orderItemArray) {
    const orderItems = [];
    const notShippedOrderItems = [];

    orderItemArray.forEach((orderItemObject) => {
      const orderItem = _.clone(orderItemObject);

      // Format serial numbers
      if (_.isArray(orderItem.serial_nos)) {
        orderItem.serials = orderItem.serial_nos.join(', ');
      }

      // If there is an discount or not.
      const extra = orderItem.extra || {};
      const { ppu_wt, before_discount_ppu_wt } = orderItem;

      // old fallback way of checking for discounts
      orderItem.is_discounted = before_discount_ppu_wt > ppu_wt;
      // Check if discount_ppu_wt is set
      if ('discount_ppu_wt' in extra) {
        orderItem.is_discounted = extra.discount_ppu_wt > 0;
      }

      // set the correct beforeDiscountPpuWt
      orderItem.before_discount_ppu_wt = before_discount_ppu_wt;
      if ('before_discount_after_length_ppu_wt' in extra) {
        // The before_discount_after_length_ppu_wt is the ppu_wt after the length was applied, but before the discount was applied
        orderItem.before_discount_ppu_wt = extra.before_discount_after_length_ppu_wt;
      }

      orderItems.push(orderItem);
      if (orderItem.still_to_be_shipped_quantity) notShippedOrderItems.push(orderItem);
    });

    return { orderItems, notShippedOrderItems };
  },

  formatInvoiceRows(invoiceRowsArray) {
    const invoiceRows = [];

    invoiceRowsArray.forEach((invoiceRowObject) => {
      const item = _.clone(invoiceRowObject);

      const { ppu_wt, discount_ppu_wt } = item;

      item.is_discounted = discount_ppu_wt != 0;

      if (item.is_discounted) {
        const discountPpuWt = Currency.toCurrency(ppu_wt);
        const ppuWt = Currency.toCurrency(discount_ppu_wt);
        item.before_discount_ppu_wt = Currency.Math.subtract(ppuWt, discountPpuWt);
      } else {
        item.before_discount_ppu_wt = ppu_wt;
      }

      invoiceRows.push(item);
    });

    return invoiceRows;
  },

  getOrderDiscount(orderItems) {
    let order_discount = '0.00';
    let has_order_discount = false;

    orderItems.forEach((item) => {
      const { extra, quantity } = item;
      if (quantity && extra && 'discount_ppu_wt' in extra) {
        has_order_discount = true;
        /** Calculate discount price */
        const discount_price_wt = Currency.Math.mul(
          Currency.toCurrency(quantity),
          Currency.toCurrency(extra.discount_ppu_wt),
        );
          /** Sumup discount */
        order_discount = Currency.Math.add(
          order_discount,
          discount_price_wt,
        );
      }
    });

    // Return the orderDiscount if there is any
    if (has_order_discount) return Currency.currencyToFloat(order_discount);

    // Else we return null
    return null;
  },

  getReceiptData(options = {}) {
    let headerText = ShopPosSetting.get('receipt_top_text') || false;
    let footerText = ShopPosSetting.get('receipt_bottom_text') || false;

    if (options && 'headerText' in options) headerText = options.headerText;
    if (options && 'footerText' in options) footerText = options.footerText;

    return {
      // Receipt
      currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
      header_text: headerText,
      footer_text: footerText,
    };
  },

  getReceiptDataForOrder(orderModel, paymentMethodCollection, options = {}) {
    const { orderItems, notShippedOrderItems } = this.formatOrderItems(orderModel.get('order_items') || []);
    const { loyaltyPaymentData = null } = options;

    // Get the correct orderDiscount
    let totalOrderDiscount = this.getOrderDiscount(orderItems);
    if (totalOrderDiscount === null) {
      // discount_ppu_wt is not set on the order items > Fallback to discount_value_wt
      totalOrderDiscount = orderModel.get('discount_value_wt');
    }

    let templateData = {
      // top
      customer_name: this._getOrderCustomerName(orderModel),
      description: orderModel.get('description'),
      // Receipt
      order_items: orderItems,
      has_not_shipped_order_items: notShippedOrderItems.length > 0,
      not_shipped_order_items: notShippedOrderItems,
      vat_totals: this.formatVatTotals(orderModel.get('order_taxes') || []),
      value: orderModel.get('value'),
      value_wt: orderModel.get('value_wt'),
      total_discount_wt: totalOrderDiscount,
      cashier_name: orderModel.get('created_by.name'),
      date_purchased: orderModel.get('date_purchased'),
      number: orderModel.get('number'),
      barcode: this.textToBarcode(orderModel.get('number')),
      has_discount: totalOrderDiscount > 0,
      transaction_signature: orderModel.get('transaction_signature'),
      was_refunded: orderModel.get('was_refunded') || false,
      refund_value: orderModel.get('refund_value') || null,
      refund_value_wt: orderModel.get('refund_value_wt') || null,
      refunded_items: orderModel.get('refunded_items') || null,
    };
    templateData = _.extend({}, templateData, this.getReceiptData(options));
    if (paymentMethodCollection) {
      templateData = _.extend({}, templateData, {
        payment_methods: this._getReceiptPaymentMethods(paymentMethodCollection),
        change: paymentMethodCollection.getSpareChangeWt(Currency.toCurrency(orderModel.get('value_wt'))),
        pin_receipt: this._getPaymentMethodPinReceipt(paymentMethodCollection),
      });

      if (loyaltyPaymentData !== null) {
        templateData = _.extend({}, templateData, loyaltyPaymentData);
      }
    }
    return templateData;
  },

  getReceiptDataForRepair(repairModel, options = {}) {
    let templateData = {
      customer_name: repairModel.get('relation_data.name'),
      description: repairModel.get('description'),
      cashier_name: ProfileModel.getFullProfileName().trim(),
      repaired_item_name: repairModel.get('repaired_item_name'),
      repaired_item_serial_no: repairModel.get('repairModel') || null,
      max_agreed_cost: repairModel.get('max_agreed_cost'),
      number: repairModel.get('number'),
      barcode: this.textToBarcode(repairModel.get('number')),
      repair_receipt_footnote_text: DefaultShopConfigurationModel.get('repair_receipt_footnote_text'),
      repair_question_answers: repairModel.get('repair_question_answers'),
    };

    templateData = _.extend({}, templateData, this.getReceiptData(options));

    return templateData;
  },

  formatInvoiceTotalDiscount(invoice_rows) {
    let total_discount_wt = '0.00';
    let has_discount = false;

    invoice_rows.forEach((item) => {
      const { quantity, discount_ppu_wt } = item;
      if (discount_ppu_wt != 0) {
        has_discount = true;
        const discount_price_wt = Currency.Math.mul(
          Currency.toCurrency(quantity),
          Currency.toCurrency(discount_ppu_wt),
        );
        total_discount_wt = Currency.Math.add(
          total_discount_wt,
          discount_price_wt,
        );
      }
    });
    return { total_discount_wt, has_discount };
  },

  getReceiptDataForInvoice(invoiceModel, paymentMethodCollection, options = {}) {
    const invoice_rows = this.formatInvoiceRows(invoiceModel.get('invoice_rows') || []);
    const { total_discount_wt, has_discount } = this.formatInvoiceTotalDiscount(invoice_rows);
    const { loyaltyPaymentData = null } = options;

    let templateData = {
      customer_name: invoiceModel.get('relation_data_to.name'),
      invoice_rows,
      vat_totals: this.formatVatTotals(invoiceModel.get('invoice_taxes') || []),
      value: invoiceModel.get('value'),
      total_discount_wt,
      has_discount,
      value_wt: invoiceModel.get('value_wt'),
      cashier_name: ProfileModel.getFullProfileName(),
      date_purchased: invoiceModel.get('date_invoiced'),
      number: invoiceModel.get('number'),
      barcode: this.textToBarcode(invoiceModel.get('number')),
      transaction_signature: invoiceModel.get('transaction_signature'),
    };
    templateData = _.extend({}, templateData, this.getReceiptData());
    if (paymentMethodCollection) {
      templateData = _.extend({}, templateData, {
        payment_methods: this._getReceiptPaymentMethods(paymentMethodCollection),
        change: paymentMethodCollection.getSpareChangeWt(),
        pin_receipt: this._getPaymentMethodPinReceipt(paymentMethodCollection),
      });

      if (loyaltyPaymentData !== null) {
        templateData = _.extend({}, templateData, loyaltyPaymentData);
      }
    }

    return templateData;
  },

  createReceiptJson(orderModel, paymentMethodCollection, options = {}) {
    const templateData = this.getReceiptDataForOrder(orderModel, paymentMethodCollection, options);
    templateData.options = options.template;

    return new ReceiptPrintable().build(templateData);
  },

  createInvoiceReceiptJson(invoiceModel, paymentMethodCollection, options = {}) {
    const templateData = this.getReceiptDataForInvoice(
      invoiceModel,
      paymentMethodCollection,
      options,
    );

    return new InvoiceReceiptPrintable().build(templateData);
  },

  createRepairReceiptJson(repairModel) {
    const templateData = this.getReceiptDataForRepair(repairModel);

    return new RepairReceiptPrintable().build(templateData);
  },

  renderReceiptFromOrder(orderModel, options = {}) {
    const def = new $.Deferred();

    PaymentMethodItemComponent.getPaymentCollectionFromOrder(orderModel)
      .then((paymentCollection) => {
        def.resolve(
          this.createReceiptJson(orderModel, paymentCollection, options),
        );
      }, def.reject);

    return def;
  },

  formatVatTotals(orderTaxes) {
    return orderTaxes.map((taxItem) => {
      const taxPercentage = (parseFloat(taxItem.tax) * 100).toFixed(0);
      return {
        total: Currency.currencyToFloat(taxItem.value).toFixed(2),
        label: `${taxPercentage}%`,
      };
    });
  },

  _getReceiptPaymentMethods(paymentMethodCollection) {
    const paymentMethods = [];

    // Update variables
    paymentMethodCollection.each((payment) => {
      if (payment.get('ppu_wt') !== '0.00') {
        paymentMethods.push({
          title: payment.get('title'),
          ppu_wt: payment.get('ppu_wt'),
        });
      } else if (payment.get('refund_amount') !== '0.00') {
        paymentMethods.push({
          title: payment.get('title'),
          ppu_wt: payment.get('refund_amount'),
        });
      }
    });

    return paymentMethods;
  },

  _getPaymentMethodPinReceipt(paymentMethodCollection) {
    let pinReceipt = false;
    paymentMethodCollection.each((paymentMethodModel) => {
      if (
        paymentMethodModel.get('id').startsWith(paymentMethodCollection.PIN_METHOD)
                    && paymentMethodModel.has('pinReceipt')
      ) {
        pinReceipt = this.parsePinTicket(paymentMethodModel.get('pinReceipt'));
      }
    });
    return pinReceipt;
  },

  _getOrderCustomerName(orderModel) {
    let customerName = orderModel.get('shipping_address.name');
    if (orderModel.has('billing_address.name')) {
      customerName = orderModel.get('billing_address.name');
    }
    return customerName;
  },

  printKitchenReceipt(orderModel, templateOptions = {}) {
    const def = new $.Deferred();

    const templateData = this.getReceiptDataForOrder(orderModel);
    templateData.options = templateOptions;

    let jsonData = new KitchenReceiptPrintable().build(templateData);
    jsonData = this.applyReceiptTemplateOptions(jsonData, templateOptions);

    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printOnlineFoodOrderKitchenReceipt(data, templateOptions = {}) {
    const def = new $.Deferred();
    data.type = templateOptions.is_reprint ? 'dupe' : 'production';
    if (templateOptions.is_reprint) {
      data.type_name = Locale.translate('dupe');
    } else {
      data.type_name = Locale.translate('production');
    }

    const jsonData = new OnlineFoodOrderKitchenPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printOnlineFoodOrderDeliveryReceipt(data, templateOptions = {}) {
    const def = new $.Deferred();
    const templateData = $.extend(this.getReceiptData(), data, templateOptions);

    let jsonData = new OnlineFoodDeliveryPrintable().build(templateData);
    jsonData = this.applyReceiptTemplateOptions(jsonData, templateOptions);

    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printReceipt(orderModel, templateOptions = {}) {
    const def = new $.Deferred();

    // If the orderModel has order_vars > receipt
    if (orderModel.has('order_vars')) {
      const orderVarCollection = new Backbone.Collection(orderModel.get('order_vars'));

      if (orderModel.get('was_refunded')) {
        const escposRefundReceipt = orderVarCollection.findWhere({ alias: orderModel.get('last_refund_order_vars_alias') });

        // Check if the receipt model exists
        if (escposRefundReceipt) {
          this.printReceiptBody(escposRefundReceipt.get('value'), orderModel, templateOptions)
            .then(def.resolve, def.reject);

          return def;
        }
      }

      // For support with old receipt
      const htmlReceipt = orderVarCollection.findWhere({ alias: 'receipt_html' });
      if (htmlReceipt) {
        this.printReceiptBodyHTML(htmlReceipt.get('value'))
          .then(def.resolve, def.reject);

        return def;
      }

      const escposReceipt = orderVarCollection.findWhere({ alias: 'receipt_escpos' });

      // Check if the receipt model exists
      if (escposReceipt) {
        this.printReceiptBody(escposReceipt.get('value'), orderModel, templateOptions)
          .then(def.resolve, def.reject);

        return def;
      }
    }

    // This receipt cannot be a reprint if it was not present in the order_vars.
    if (!('is_reprint' in templateOptions)) {
      templateOptions.is_reprint = false;
    }

    this.renderReceiptFromOrder(orderModel, { template: templateOptions }).then((receiptJson) => {
      orderModel.set('order_vars', [{
        alias: 'receipt_escpos',
        value: receiptJson,
      }]);
      OrderReceipt.saveOrderReceiptJob(orderModel.get('id'), receiptJson);
      this.printReceiptBody(receiptJson, orderModel, templateOptions).then(def.resolve, def.reject);
    }, def.reject);

    return def;
  },

  printInvoiceReceipt(invoiceModel, paymentCollection, templateOptions = {}) {
    const def = new $.Deferred();

    const printInvoice = (payments) => {
      let receiptJson = this.createInvoiceReceiptJson(invoiceModel, payments, templateOptions);
      receiptJson = this.applyReceiptTemplateOptions(receiptJson, templateOptions);

      ReceiptPrinterModel.printJsonData(receiptJson).then(def.resolve, def.reject);
    };

    if (paymentCollection) {
      // The payment collection was already given
      printInvoice(paymentCollection);
    } else {
      // We still need to fetch the payment collection
      PaymentMethodItemComponent.getPaymentCollectionFromInvoice(invoiceModel)
        .then((fetchedCollection) => {
          printInvoice(fetchedCollection);
        }, def.reject);
    }

    return def;
  },

  printRepairReceipt(repairModel) {
    const def = new $.Deferred();

    const receiptJson = this.createRepairReceiptJson(repairModel);
    ReceiptPrinterModel.printJsonData(receiptJson).then(def.resolve, def.reject);

    return def;
  },

  printReceiptBody(jsonData, orderModel, templateOptions) {
    jsonData = this.applyReceiptTemplateOptions(jsonData, templateOptions);

    return ReceiptPrinterModel.printJsonData(jsonData);
  },

  printReceiptBodyHTML(html, templateOptions) {
    const def = new $.Deferred();

    this.applyReceiptTemplateOptionsToHTML(html, templateOptions).then((data) => {
      ReceiptPrinterModel.printHtml(data).then(def.resolve, def.reject);
    }, def.reject);

    return def;
  },

  applyReceiptTemplateOptions(jsonData, templateOptions = {}) {
    const builder = EscPos.EscPosBuilder.from(jsonData);

    // Replace {~reprint~} text command
    const reprintCommandIndex = builder.commands.findIndex((command) => command.key === 'text' && command.text === '{~reprint~}');
    if (reprintCommandIndex > -1) {
      if (templateOptions.is_reprint) {
        // Replace the given index and add the following commands
        builder.commands.splice(reprintCommandIndex, 1,
          new EscPos.TextCommand(Locale.translate('copy_physical_duplicate'), {
            alignHT: 'center', size: 2, bold: true, newLine: true,
          }),
          new EscPos.TextCommand(`${Locale.translate('print_date')}: ${Moment().format('LLL')}`, { alignHT: 'center', bold: true, newLine: true }),
          new EscPos.LineFeedCommand(1));
      } else {
        // If there is no reprint, just remove the text
        builder.commands.splice(reprintCommandIndex, 1);
      }
    }

    return builder.serialize();
  },

  applyReceiptTemplateOptionsToHTML(receiptBodyHtml, templateOptions) {
    const def = new $.Deferred();

    templateOptions = _.extend({}, {
      is_reprint: false,
      logoHtml: false,
      preLogoHtml: false,
      postLogoHtml: false,
    }, templateOptions);

    $.when(
      this.fetchLogo(),
    ).then((companyLogo) => {
      // Company logo
      let logoHtml = '';

      if (_.isString(templateOptions.preLogoHtml)) {
        logoHtml += templateOptions.preLogoHtml;
      }

      if (templateOptions.logoHtml) {
        logoHtml += templateOptions.logoHtml;
      } else if (companyLogo) {
        logoHtml
          += `<img src="${companyLogo}" alt="logo" style="max-width: 100%" class="logo">`;
      }

      if (_.isString(templateOptions.postLogoHtml)) {
        logoHtml += templateOptions.postLogoHtml;
      }

      // Reprint html
      let reprintHtml = '';
      let reprintDateHtml = '';
      if (templateOptions.is_reprint) {
        const style = 'text-align: center;padding: 0;margin: 0;';
        reprintHtml = `<h2 style="${style}" class="capitalize">${Locale.translate('copy_physical_duplicate')}</h2>`;
        reprintDateHtml = `<h5 style="${style}">${Locale.translate('print_date')}: ${Moment().format('LLL')}</h5>`;
      }

      // Replace placeholders in the body
      receiptBodyHtml = receiptBodyHtml.replace('{~logo~}', logoHtml);
      receiptBodyHtml = receiptBodyHtml.replace('{~reprint~}', reprintHtml);
      receiptBodyHtml = receiptBodyHtml.replace('{~reprint_date~}', reprintDateHtml);

      def.resolve(receiptBodyHtml);
    }, def.reject);

    return def;
  },

  printDailyReturns(orderItemCollection) {
    const def = new $.Deferred();

    const data = {
      order_items: orderItemCollection.toJSON(),
    };

    const jsonData = new DailyReturnsPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printHtmlContent(html, options) {
    const def = new $.Deferred();

    const renderedHtml = ContentTemplate({
      content: html,
    });
    ReceiptPrinterModel.printHtml(renderedHtml, options)
      .then(def.resolve, def.reject);

    return def;
  },

  createBreadStickerTemplateData(quantity, price_incl_vat, product_name, description, current_date, barcode_text) {
    price_incl_vat = price_incl_vat || 0;
    quantity = quantity || 1;
    product_name = product_name || '--';
    description = new MarkdownIt().render(_.escape(description)) || '';

    const templateData = {
      price: price_incl_vat.toFixed(2),
      product_name,
      quantity,
      description,
      current_date,
    };

    if (barcode_text) {
      templateData.barcode_text = barcode_text;
      templateData.barcode = this.textToBarcode(barcode_text);
    }

    return templateData;
  },

  createPriceTemplateData(ppu, default_ppu, has_discount, product_name, sku, barcodeText) {
    ppu = ppu || 0;
    default_ppu = default_ppu || ppu;
    product_name = product_name || '--';

    if (ppu === default_ppu) {
      has_discount = false;
    }
    const templateData = {
      price: ppu.toFixed(2),
      default_ppu: default_ppu.toFixed(2),
      has_discount,
      // 23 is the maximum amount of characters that will fit on a single line
      product_name,
      sku,
    };

    if (barcodeText) {
      templateData.barcode_text = barcodeText;
      templateData.barcode = this.textToBarcode(barcodeText);
    }

    return templateData;
  },

  getFontUrl(fontFileName) {
    return `${location.origin}/fonts/${fontFileName}`;
  },

  printPriceTagFromData(ppu, default_ppu, has_discount, product_name, sku, barcodeText) {
    const def = new $.Deferred();

    // Template
    const templateData = this.createPriceTemplateData(ppu, default_ppu, has_discount, product_name, sku, barcodeText);
    templateData.price_tag_font_url = this.getFontUrl('B612Mono-Regular.ttf');
    const renderedHtml = PriceTagTemplate(templateData);

    // print
    PriceTagPrinterModel.printHtml(renderedHtml, {
      type: 'pricetag',
    }).then(def.resolve, def.reject);

    return def.promise();
  },

  printPriceTag(productModel) {
    const contentVars = productModel.get('flat_product.content_vars');
    let barcodeText = false;

    let printableShortnameText = false;

    if (!FeaturedAttributeCollection.getAttributeByAlias(FeaturedAttributeCollection.ALIAS_BARCODE)) {
      console.warn('Featured attribute barcode not set');
    }

    if (contentVars) {
      contentVars.forEach((contentVar) => {
        const attr_id = contentVar.attribute_id;
        if (attr_id === FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_BARCODE)) {
          if ('value_label' in contentVar) {
            barcodeText = contentVar.value_label;
          } else {
            barcodeText = contentVar.value;
          }
        } else if (attr_id === FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_PRINTABLE_SHORTNAME)) {
          if ('value_label' in contentVar) {
            printableShortnameText = contentVar.value_label;
          } else {
            printableShortnameText = contentVar.value;
          }
        }
      });
    }

    const ppu = productModel.get('product_price.ppu_wt');
    const default_ppu = productModel.get('product_default_price.ppu_wt');
    const has_discount = !(productModel.get('product_price_id') === productModel.get('product_default_price_id'));
    const product_name = printableShortnameText || productModel.get('flat_product.title');
    const sku = productModel.get('flat_product.product.sku');
    return this.printPriceTagFromData(ppu, default_ppu, has_discount, product_name, sku, barcodeText);
  },

  printBreadStickerFromData(quantity, price_incl_vat, iso3, product_name, description, barcode_text) {
    const def = new $.Deferred();

    const current_date = Moment().format('DD-MM-YYYY');
    const templateData = this.createBreadStickerTemplateData(quantity, price_incl_vat, product_name, description, current_date, barcode_text);
    templateData.iso3 = iso3;
    let renderedHtml = BreadStickerTemplate(templateData);

    $.when(
      this.fetchLogo(),
    ).then((companyLogo) => {
      const logoHtml = `<img src="${companyLogo}" alt="logo" style="max-width: 100%" class="logo">`;
      renderedHtml = renderedHtml.replace('{~logo~}', logoHtml);

      // Print
      StickerPrinterModel.printHtml(renderedHtml, {
        type: 'sticker',
      }).then(def.resolve, def.reject);
    }, def.reject);

    return def.promise();
  },

  printPriceStickerFromData(ppu, default_ppu, has_discount, iso3, product_name, sku, barcodeText) {
    const def = new $.Deferred();

    // Template
    const templateData = this.createPriceTemplateData(ppu, default_ppu, has_discount, product_name, sku, barcodeText);
    templateData.iso3 = iso3;
    const renderedHtml = PriceStickerTemplate(templateData);

    // Print
    StickerPrinterModel.printHtml(renderedHtml, {
      type: 'sticker',
    }).then(def.resolve, def.reject);

    return def.promise();
  },

  printPriceSticker(productModel) {
    const contentVars = productModel.get('flat_product.content_vars');
    let barcodeText = false;

    let printableShortnameText = false;

    if (!FeaturedAttributeCollection.getAttributeByAlias(FeaturedAttributeCollection.ALIAS_BARCODE)) {
      console.warn('Featured attribute barcode not set');
    }

    if (contentVars) {
      contentVars.forEach((contentVar) => {
        const attr_id = contentVar.attribute_id;
        if (attr_id === FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_BARCODE)) {
          if ('value_label' in contentVar) {
            barcodeText = contentVar.value_label;
          } else {
            barcodeText = contentVar.value;
          }
        } else if (attr_id === FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_PRINTABLE_SHORTNAME)) {
          if ('value_label' in contentVar) {
            printableShortnameText = contentVar.value_label;
          } else {
            printableShortnameText = contentVar.value;
          }
        }
      });
    }

    if (MediaTypeModel.get('id') === MediaTypeModel.TYPE_PRICE_STICKER) {
      var ppu = productModel.get('product_price.ppu_wt');
      const default_ppu = productModel.get('product_default_price.ppu_wt');
      const has_discount = !(productModel.get('product_price_id') === productModel.get('product_default_price_id'));
      var iso3 = productModel.get('product_price.currency_iso3');
      var product_name = printableShortnameText || productModel.get('flat_product.title');
      const sku = productModel.get('flat_product.product.sku');
      return this.printPriceStickerFromData(ppu, default_ppu, has_discount, iso3, product_name, sku, barcodeText);
    }
    var ppu = productModel.get('product_price.ppu_wt');
    var iso3 = productModel.get('product_price.currency_iso3');
    var product_name = printableShortnameText || productModel.get('flat_product.title');
    const description = productModel.get('flat_product.body');

    return this.printBreadStickerFromData(1, ppu, iso3, product_name, description, barcodeText);
  },

  printMutation(shopLedgerOperationModel, direction) {
    const def = new $.Deferred();
    const templateData = {
      mutation_date: new Date(),
      mutation_type: direction,
      mutation_amount: shopLedgerOperationModel.get('balance_change'),
      mutation_note: shopLedgerOperationModel.get('note'),
      sales_point: PointOfSaleModel.get('name'),
      person: ProfileModel.getFullProfileName(),
    };

    const jsonData = new MutationPrintable().build(templateData);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printDailyTurnover(data) {
    const def = new $.Deferred();
    const jsonData = new DailyTurnoverPrintable().build(data);

    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  parsePinTicketTextData(ticketData) {
    const splitTicketFiltered = [];
    const splitTicket = ticketData.split('\n');

    if (splitTicket > 1) {
      splitTicket.forEach((rawString) => {
        const string = rawString.trim();
        if (
          string !== ''
          && string.indexOf('---') === -1
        ) {
          splitTicketFiltered.push(_.escape(string));
        }
      });

      // Removes the last 2 items, which are BEDANKT and TOT ZIENS
      splitTicketFiltered.length -= 2;
    }

    return splitTicketFiltered;
  },

  parsePinTicket(ticketData) {
    const splitTicketFiltered = [];
    const splitTicket = ticketData.split('\n');

    if (splitTicket.length > 1) {
      splitTicket.forEach((rawString) => {
        const string = rawString.trim();
        if (
          string !== ''
          && string.indexOf('---') === -1
        ) {
          splitTicketFiltered.push(_.escape(string));
        }
      });

      // Removes the last 2 items, which are BEDANKT and TOT ZIENS
      splitTicketFiltered.length -= 2;

      let newTicket = '';
      const uppercaseRegex = /^[A-Z]*$/;
      splitTicketFiltered.forEach((string) => {
        if (string.match(uppercaseRegex)) {
          newTicket += `\n${string}\n`;
        } else {
          newTicket += `${string}\n`;
        }
      });

      return newTicket;
    }

    return ticketData;
  },

  getOrderEmail(orderModel) {
    return Email.cleanEmptyEmail(orderModel.get('shipping_address.contact_address.contact_set.email'))
      || Email.cleanEmptyEmail(orderModel.get('shipping_address.contact_set.email'))
      || Email.cleanEmptyEmail(orderModel.get('address_billing.contact_set.email'));
  },
  getOrderPhone(orderModel) {
    return orderModel.get('shipping_address.contact_address.contact_set.phone')
      || orderModel.get('shipping_address.contact_set.phone')
      || orderModel.get('address_billing.contact_set.phone');
  },

  printOrderShipmentContents(orderModel, shipmentId) {
    const def = new $.Deferred();

    const data = orderModel.toJSON();
    data.header_text = ShopPosSetting.get('receipt_top_text') || false;
    data.footer_text = ShopPosSetting.get('receipt_bottom_text') || false;
    data.email = this.getOrderEmail(orderModel);
    data.phone = this.getOrderPhone(orderModel);

    const shipmentModel = orderModel.getShipmentItemCollection().get(shipmentId);

    data.shipment_method_name = shipmentModel.get('shipping_method.name');
    data.shipment = shipmentModel.toJSON();

    const countryModel = CountryCollection.findWhere({ iso2: orderModel.get('shipping_address.contact_address.country_iso2') });
    if (countryModel) {
      data.country = countryModel.get('name');
    }

    const jsonData = new ShipmentContentsPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printOrderShipmentDelivery(orderModel, shipmentId) {
    const def = new $.Deferred();

    const data = orderModel.toJSON();
    data.header_text = ShopPosSetting.get('receipt_top_text') || false;
    data.footer_text = ShopPosSetting.get('receipt_bottom_text') || false;

    const shipmentModel = orderModel.getShipmentItemCollection().get(shipmentId);
    data.shipment = shipmentModel.toJSON();

    const jsonData = new ShipmentDeliveryPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printOrderPackingSlip(orderModel) {
    const def = new $.Deferred();

    const data = orderModel.toJSON();
    data.header_text = ShopPosSetting.get('receipt_top_text') || false;
    data.footer_text = ShopPosSetting.get('receipt_bottom_text') || false;
    data.email = this.getOrderEmail(orderModel);
    data.phone = this.getOrderPhone(orderModel);
    data.shipping = {
      name: ShippingMethod.getNameForOrder(orderModel),
    };
    data.pickup_date = null;

    if (orderModel.has('pickup_date') && orderModel.get('pickup_date')) {
      data.pickup_date = Moment(orderModel.get('pickup_date')).locale(Locale.getLocale()).format('L');
    }

    const countryModel = CountryCollection.findWhere({ iso2: orderModel.get('shipping_address.contact_address.country_iso2') });
    if (countryModel) {
      data.country = countryModel.get('name');
    }

    const jsonData = new PackingSlipPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printCashup(data) {
    const def = new $.Deferred();

    const jsonData = new CashupPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printCCVReceipt(receipts) {
    const def = new $.Deferred();

    const jsonData = new CCVPrintable().build(receipts);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  getPpuAfterDiscountWt(item) {
    const discountPpuWt = Currency.toCurrency(item.discount_ppu_wt);
    const ppuWt = Currency.toCurrency(item.before_discount_ppu_wt);
    return Currency.Math.subtract(ppuWt, discountPpuWt);
  },

  fixSubitemsTotal(data) {
    // clone the items
    data.order_items = JSON.parse(JSON.stringify(data.order_items));

    for (const i in data.order_items) {
      const item = data.order_items[i];
      // for some reason the ppu_wt is wrong, no idea why so we recalculate it, only happens for discounts,
      // because this is being done in super urgent overnight feature
      // I will not try to fix it in order model ~~ Szymon
      if (item.discount_ppu_wt > 0) {
        item.ppu_wt = this.getPpuAfterDiscountWt(item);
      }
      const subitems = item.sub_items || item.subitems;
      if (subitems) {
        for (const i in subitems) {
          const subItem = subitems[i];
          if (subItem.discount_ppu_wt > 0) {
            subItem.ppu_wt = this.getPpuAfterDiscountWt(subItem);
          }
        }
      }
    }
  },

  printTabOrder(data) {
    const def = new $.Deferred();
    this.fixSubitemsTotal(data);

    data.header_text = ShopPosSetting.get('receipt_top_text') || false;
    data.footer_text = ShopPosSetting.get('receipt_bottom_text') || false;

    const jsonData = new TabSummaryPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printTableOrder(data) {
    const def = new $.Deferred();
    this.fixSubitemsTotal(data);

    data.header_text = ShopPosSetting.get('receipt_top_text') || false;
    data.footer_text = ShopPosSetting.get('receipt_bottom_text') || false;

    const jsonData = new TableSummaryPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  printKitchenTableOrder(data) {
    const def = new $.Deferred();

    const jsonData = new TabSummaryPrintable().build(data);
    ReceiptPrinterModel.printJsonData(jsonData).then(def.resolve, def.reject);

    return def;
  },

  toDataURL(url) {
    const def = new $.Deferred();
    if (url.trim() === '') {
      return def.resolve();
    }

    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        def.resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();

    return def;
  },

  textToBarcode(text) {
    const canvas = document.createElement('canvas');
    const options = { format: 'CODE128', height: 60, displayValue: false };

    // CODE128: https://en.wikipedia.org/wiki/Code_128
    // Should cover everything needed for us
    JsBarcode(canvas, text, options);
    return canvas.toDataURL('image/png');
  },

  fetchLogo() {
    const def = new $.Deferred();
    def.fail(console.error);

    // Check if there is already some logo data set/cached.
    if (_.isNull(this.logoData)) {
      // Check if there is a black and white logo
      let url = DefaultShopConfigurationModel.getBlackAndWhiteCompanyLogo();
      if (!url) {
        // else use the coloured one
        url = DefaultShopConfigurationModel.getCompanyLogo();
      }
      if (url) {
        const self = this;
        // Fetch the logo and get a base64 version back.
        this.toDataURL(url)
          .then((logoData) => {
            self.logoData = logoData;
            def.resolve(self.logoData);
          }, def.reject);
      } else {
        def.resolve(this.logoData);
      }
    } else {
      def.resolve(this.logoData);
    }

    return def;
  },

  ensureRelation(orderModel) {
    const def = new $.Deferred();
    const wantedRelationDataId = orderModel.get('created_by_id');

    if (wantedRelationDataId in this.relationCache) {
      def.resolve(this.relationCache[wantedRelationDataId]);
    } else {
      const relationModel = new RelationModel({ id: wantedRelationDataId });
      relationModel.fetch()
        .then(() => {
          this.relationCache[wantedRelationDataId] = relationModel;
          def.resolve(relationModel);
        }, def.reject);
    }

    return def;
  },
}));
